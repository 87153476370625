/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px

* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react";
//
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
//
import { makeStyles } from '@material-ui/core/styles';
//
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//
import Section from '@molecules/Sections/Section';
import SellCarCard from '@molecules/Cards/SellCarCard';
import Button from '@atoms/Button';





/**
 * 
 */
const useStyles = makeStyles((theme) => ({
  warrp: {
    marginTop: 45,
  },
}));








export default (props) => {
  const classes = useStyles();
  /*
  *
  */
  const data = useStaticQuery(graphql`
    query IndexCarImagesQuery {
      one: file(relativePath: { eq: "cars/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      two: file(relativePath: { eq: "cars/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      three: file(relativePath: { eq: "cars/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      four: file(relativePath: { eq: "cars/4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      five: file(relativePath: { eq: "cars/5.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

      six: file(relativePath: { eq: "cars/6.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }    
    
    }
  `)


  return (
  <Section
    title="بيع سيارتك مع دوّر لي"
    className={classes.root}
  >
    <Box>

            <Grid container className={classes.warrp} spacing={4}>
               {/* in dynmic we will loop data in SellCarCard */}
               <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >
                 <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-1.png?alt=media&token=e785d20d-4dd7-4b58-89a0-1ee14e45215e'
                    name="حسن احمد"
                    date="الخميس ٢٠ مايو"
                    time="من ٨ ساعات "
                  >
                    <Img fluid={data.one.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>



                <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >
                  <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-2.png?alt=media&token=450852ee-5a43-480f-8744-89d90ec4005c'
                    name="خديجة ابو رشاد"
                    date="الاربع ١٩ مايو"
                    time=" من ١٨ ساعات"
                  >
                    <Img fluid={data.two.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>



                <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >

                  <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-3.png?alt=media&token=b25ae11d-a7fb-4347-b67a-7ddc8d741af4'
                    name="حمزة خالد "
                    date="الجمعة ٢٠ مايو"
                    time="من ٨ ساعات"
                  >
                     <Img fluid={data.three.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>


                <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >
                 <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-4.png?alt=media&token=99001060-4ff4-4722-99e2-91d6ee9852d1'
                    name=" محمود خالد"
                    date="الخميس ٢٠ مايو"
                    time=" من ٨ ساعات "
                  >
                    <Img fluid={data.four.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>



                <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >
                  <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-5.png?alt=media&token=1b4c4ac7-6329-44a8-9cf3-11e4fb1d372a'
                    name= "مريم البنا "
                    date="الاربع ١٩ مايو"
                    time=" من ١٨ ساعات"
                  >
                    <Img fluid={data.five.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>


                <Grid item sm={12} xs={12} md={6} lg={4}  xl={4} >
                  <SellCarCard 
                    avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-6.png?alt=media&token=b09b8647-97c4-4f5b-9848-a852b155cefb'
                    name="عبد الرحمن حسن"
                    date="الجمعة ٢٠ مايو"
                    time="من ١٠ ساعات"
                  >
                    <Img fluid={data.six.childImageSharp.fluid} />
                  </SellCarCard>
                </Grid>




            </Grid>


            {/* */}
            <Button 
              variant="contained"
              color="secondary"
              style={{  marginTop: 30, marginright: 0}}
            >
              شاهد المزيد 
            </Button>


      </Box>

    </Section>
  );
};
