import React from "react";
// material-ui method
import { makeStyles } from '@material-ui/core/styles';
// matrial-ui component
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


/**
 * @custom_style
 */
const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 70,
    marginTop: '5%'
  },
  head: {
    textAlign: 'center'
  },
  title : {
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '30px',
    color: '#201F1F',
    position: 'relative',
    display: 'inline-block',
    // responsive
    [theme.breakpoints.up('md')]: {
      fontSize: 25
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18
    },

    // 
    '&::before': {
        content: "''",
        backgroundColor: '#f55739',
        width: 4,
        height: 22,
        position: 'absolute',
        right: '-15px',
        top: 6
      }
  },
}));



/**
 * *
 */
export default (props) => {
  const classes = useStyles();

  return (
    <Box component="section" id={props.id} className={classes.section}>

      <Box className={classes.head}>
        <Typography variant="h3" gutterBottom  className={classes.title}>
          {props.title}
        </Typography>
      </Box>

      {props.children}

   </Box>
  );
};
