/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px

* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react";
// import material-ui methods
import {  makeStyles } from '@material-ui/core/styles';
// import material-ui components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
// import molecules
import Section from '@molecules/Sections/Section';
import WorkWayCard from '@molecules/Cards/WorkWayCard';


/**
 * @work_way_data
 */
const workWays = [
{
  number: '١',
  color: "#394CF5",
  title: "عطنا معلومات السيارة",
  desc: "أدخل السجل الخاص بك للحصول على تقييم فوري بناءً . على أحدث بيانات المبيعات لدينا, ثم نقوم بإرشادك من خلال إنشاء ملف تعريف لسيارتك"
},
{
  number: '٢',
  color: "#C70039",
  title: "ادفع العمولة",
  desc: "أدخل السجل الخاص بك للحصول على تقييم فوري بناءً . على أحدث بيانات المبيعات لدينا, ثم نقوم بإرشادك من خلال إنشاء ملف تعريف لسيارتك"
},
{
  number: '	٣',
  color: "#1E6F5C",
  title: " نصل إلى العمل ",
  desc: "نقوم بتنبيه شبكتنا التي تضم أكثر من 3000 تاجر سيارات معتمد بشأن سيارتك. وندعوهم إلى المزايدة عليها في وندعوهم إلى المزايدة عليها في عملية بيع يومية عبر الإنترنت"
},
{
  number: '٤',
  color: "#FECD1A",
  title: "احصل على أفضل عرض لديك",
  desc: "سنقدم أفضل عرض من البيع للحصول على موافقتك ثم نرتب موعدًا مناسبًا لجمع سيارتك"
},
];






/**
 *  @custom_style
 */
 const useStyles = makeStyles((theme) => ({
  warrp: {
    flexGrow: 1,
    margin: '45px auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '80%'
    },
  },
}));



/**
 * 
 * 
 */
export default (props) => {
  const classes = useStyles();

  return (
    <div>
    <Section
      id="workway"
      title="طريقة عملنا"
    >

      <Box  className={classes.warrp}>
          <Grid container spacing={3}>

            { workWays.map(( way , index) => (
              <Grid  item xs={6}   sm={12}   xs={12} md={6}  lg={6}  xl={6}   key={index}>
                  <WorkWayCard 
                    number={way.number}
                    color={way.color}
                    tittle={way.title}
                    desc={way.desc}
                  />
              </Grid>
            ))}

          </Grid>
      </Box>

    </Section>
   </div>
  );
};
