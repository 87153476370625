import React from "react";
import Box from '@material-ui/core/Box';




function ClockIcon(props) {
  return (
  <Box  {...props} >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#201F1F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
      ></path>
      <path stroke="#201F1F" strokeWidth="1.25" d="M10 9v5h4"></path>
    </svg>
  </Box>
  );
}

export default ClockIcon;