import React from 'react';
//
import { makeStyles } from '@material-ui/core/styles';
// gatsbyjs
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
// matrial-ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
/** */
import Card from '@material-ui/core/Card';
/***/ 
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';

//icons
import CalenderIcon from '@icons/CalenderIcon';
import ClockIcon from '@icons/ClockIcon';



/**
 * 
 */
const useStyles = makeStyles((theme) => ({
  root: {
    //maxWidth: 345,
    boxShadow: '25px 25px 54px rgba(0, 0, 0, 0.08)',
    backgroundColor: "none",
    borderRadius: 5,
    '& > *': {
        padding: 0, 
    }
  },

  header: {
    padding: 0,
    '& > *': {
      fontWeight: 500,
      margin: 9
     }
  },

  avatar: {
    width: 22,
    height: 22
  },


  image: {
    width: 178,
    height: 122,
    float: 'left'
  },    
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 5
  },

  /**/ 
  list: {
    paddingTop: 15,
    paddingRight: 20
  },
  listItem: {
    padding: 0,
    textAlign: 'right'
  },
  itemAvatar: {
    width: 'auto',
    minWidth: 32
  },


}));



/**
 * 
 * 
 */
export default function SellCarCard({ children, avatar, name, date,time }) {
    const classes = useStyles();


  return (
    <Card className={classes.root}>

         {/* */}
        <Grid container spacing={0}>

            {/* */}
            <Grid item xs>

                        <List className={classes.list}>
                            {/* */}
                            <ListItem className={classes.listItem}>
                                <ListItemAvatar className={classes.itemAvatar}>
                                  <Avatar alt={name} src={avatar}   className={classes.avatar} />
                                </ListItemAvatar>
                                <ListItemText primary={name}  />
                            </ListItem>

                            {/* */}
                            <ListItem className={classes.listItem}>
                                <ListItemIcon  className={classes.itemAvatar}>
                                    <CalenderIcon />
                                </ListItemIcon>
                                <ListItemText primary={date}  />
                            </ListItem>

                            {/* */}
                            <ListItem className={classes.listItem}>
                                <ListItemIcon  className={classes.itemAvatar}>
                                    <ClockIcon />
                                </ListItemIcon>
                                <ListItemText primary={time}  />
                            </ListItem>

                        </List>

            </Grid>



                {/* */}
                <Grid item xs>        
                    {/* */}
                    <Box className={classes.image}>
                        {/*render car image form propd */}
                         { children }
                    </Box>
                </Grid>



        </Grid>

    </Card>
  );
}