import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


//
import IconAvatar from "@atoms/IconAvatar"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: '0 0 0',
    background: 'none',
    overflow: "initial"
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    lineHeight: '2.334',
    // responsive
    [theme.breakpoints.up('md')]: {
      fontSize: 21
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 19
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 17
    },
  },
  content: {
    flex: '1 0 auto',
    padding: 25
  },
}));


/* 

**/

export default function WorkWayCard({ number, color, tittle, desc }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>


     {/* */}
      <Box
        className={classes.cover}
      >
        <IconAvatar color={color} number={number}/>

      </Box>
 

    {/* */}
      <Box className={classes.details}>
        <CardContent className={classes.content}>

          <Typography 
            component="h5" 
            variant="h5"
            className={classes.title}
          >
            { tittle }
          </Typography>

          <Typography variant="subtitle1" color="textSecondary">
                   {desc}
          </Typography>


        </CardContent>
      </Box>


    </Card>
  );
}