import React from "react";
import Box from '@material-ui/core/Box';



function CalenderIcon(props) {
  return (
    <Box  {...props} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#201F1F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 4H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM3 10h18M16 2v4M8 2v4"
        ></path>
      </svg>
    </Box>
  );
}

export default CalenderIcon;