import React from 'react';
//
import { makeStyles } from '@material-ui/core/styles';

//
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

/**/ 
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

//
import Rating from '@atoms/Rating';
import Avatar from '@atoms/Avatar';






const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '25px 25px 54px rgba(0, 0, 0, 0.08)',
    backgroundColor: "none",
    borderRadius: 15,
    padding: '20px 10px',
    '& > *': {
        padding: 0, 
    }
  },
  header: {
    '& > .MuiCardHeader-title': {
        fontWeight: 500,
        marginTop: 18
    },
    '& > *': {
      margin: 7
     }
  },
  avatar: {
    minWidth: 40,
    margin: 0,
    padding: 0
  },
  content: {
    padding: '0 53px 0px 24px',
    paddingBottom: '0 !important'
  },
  title: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '1.8'
  },

}));

export default function ReviewCard({ name, job, avatar , title, review}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>

      <CardHeader
        className={classes.header}
        avatar={
          <Avatar alt={name} src={avatar} className={classes.avatar}/>
        }
        title={name}
        subheader={job}
      />


      <CardContent  className={classes.content}>

        {/* */}
        <Typography variant="h5"  component="h5" className={classes.title}>
            {title}
        </Typography>

        {/* */}
        <Typography variant="body2" color="textSecondary" component="p">
             {review}
        </Typography>


        {/* */}
        <Box>
          <Rating />
        </Box>

      </CardContent>

    </Card>
  );
}