import React from 'react';
//
import { makeStyles } from '@material-ui/core/styles';
//
import Rating from '@material-ui/lab/Rating';






const useStyles = makeStyles((theme) => ({
  Rating: {           
    color: '#F55739',
    '& > *': {
      margin: '8px 0 0 9px'
    }
  },
}));




export default (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
        <Rating 
          {...props}
          name="read-only"
          value={5} 
          readOnly 
          className={classes.Rating}
        />
    </React.Fragment>
  );
}
