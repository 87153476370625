/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px

* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react";
//
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
//
import Section from '@molecules/Sections/Section';
import ReviewCard from '@molecules/Cards/ReviewCard';
import Button from '@atoms/Button';











export default (props) => {

  return (
    <div>
    <Section
     title="المراجعات"
    >




      <Box 
       style={{
                flexGrow: 1,
                margin: '60px auto'
        }}
      >


            <Grid container spacing={4}>



                <Grid item xs={4} sm={12} xs={12} md={4} lg={4} xl={4} >
                    <ReviewCard
                      name='حسن احمد '
                      job='محاسب'
                      avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-1.png?alt=media&token=e785d20d-4dd7-4b58-89a0-1ee14e45215e'
                      title='خدمة ممتازة'
                      review='خدمة ممتازة سهلة الاستخدام للغاية ودوران سريع وباعت سيارتى فى غضون ايام قليلة بسعر معقول...'
                    />
                </Grid>

                <Grid item xs={4} sm={12} xs={12} md={4} lg={4} xl={4} >
                    <ReviewCard
                      name='سارة هاني'
                      job='مطور برمجيات'
                      avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-2.png?alt=media&token=450852ee-5a43-480f-8744-89d90ec4005c'
                      title='خدمة ممتازة'
                      review='خدمة ممتازة سهلة الاستخدام للغاية ودوران سريع وباعت سيارتى فى غضون ايام قليلة بسعر معقول...'
                    />
                </Grid>

                <Grid item xs={4} sm={12} xs={12} md={4} lg={4} xl={4} >
                    <ReviewCard
                      name='سلمي احمد '
                      job=' مصممه ازياء '
                      avatar='https://firebasestorage.googleapis.com/v0/b/website-4d58d.appspot.com/o/avatars%2Favatar-3.png?alt=media&token=b25ae11d-a7fb-4347-b67a-7ddc8d741af4'
                      title='خدمة ممتازة'
                      review='خدمة ممتازة سهلة الاستخدام للغاية ودوران سريع وباعت سيارتى فى غضون ايام قليلة بسعر معقول...'
                    />
                </Grid>



            </Grid>



            {/* */}
            <Button 
              variant="contained"
              color="secondary"
              style={{  marginTop: 30, marginright: 0}}
            >
              شاهد المزيد 
            </Button>





      </Box>




          
    </Section>
   </div>
  );
};
