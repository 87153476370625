import React from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import  Box from '@material-ui/core/Box';
import InputField from '@atoms/InputField';
import Button from '@atoms/Button';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    margin: '27px 0'
  },
}));

export default function PriceForm(props) {
  const classes = useStyles();

  return (
    <Box className={classes.form}>
        <Button  variant="contained" color="secondary" style={{margin:0}}>
           قيمة سيارتك
        </Button>

        <InputField
            placeholder="ادخل للحصول"      

        />
    </Box>
  );
}







