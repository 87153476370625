/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px

* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react";
// gatsbyjs core
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
// material-ui method
import { makeStyles } from '@material-ui/core/styles';
// matrial-ui component
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// atoms component 
import PriceForm from '@molecules/Form/PriceForm';
import DwerliButton from '@atoms/Button';



/**
 * @custom_style
 */
const useStyles = makeStyles((theme) => ({
  // form warrp
  formConainer: {
    margin: '87px 0px',
    // responsive
    [theme.breakpoints.down('sm')]: {
      margin: 15,
      width: `100%`,
      display:' contents',
    },    

  },
  // form title
  formTitle : {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 40,
    lineHeight: '48px',
    // responsive
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      lineHeight: '35px'
    },
  },
  formSubtitle: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: '22px',
    color: 'rgba(32, 31, 31, 0.6)'
    // responsive
  },
  heroImage :{    
    position: 'relative',
    width: "112%",
    // responsive
    [theme.breakpoints.up('md')]: {
      top: 27,
      left: 100,  
    },
    [theme.breakpoints.down('md')]: {
      top: 6,
      left: 41
    },
  } 
  
}));



/**
 * 
 */
export default (props) => {
  const classes = useStyles();

  // image query from gatsby
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);


  return (
    <Box
     component="section" 
     m={1} 
     style={{
       position: 'relative'
     }}
    >

      <Grid container spacing={3}>

        {/* */}
        <Grid item  xs={12} sm={12}  md={6} lg={6} xl={6}>
      
        <Box 
            component="div" 
            className={classes.formConainer}
          >

                  <Typography variant="h3" gutterBottom className={classes.formTitle}>
                    تعبت تدوّر سيارة
                    <br />
                    أو تبيع سيارتك ؟
                  </Typography>

                  <Typography  variant="subtitle1" gutterBottom className={classes.formSubtitle}>
                      تعبت وانت تتفاهم مع الناس والشريطية؟
                    <br />
                      بنحط معلومات تواصلنا ونعطيك الزبدة!
                    <br />
                    تدور لك عن شراي او سيارة. كل هذا بـ ١٠٠ ريال لمدة شهر كامل
                  </Typography>
                  <Box>

                  {/* FORM */}
                  <PriceForm />


                  {/* ACTION FORM */}
                  <Box  style={{display: 'flex'}} >
                      <DwerliButton  variant="contained" color="secondary" style={{  margin: '8px 0 8px 15px'}}>
                        بيع سيارة
                      </DwerliButton>

                      <DwerliButton  variant="contained" color="secondary" style={{  margin: '8px 0 8px 15px'}}>
                        دورلى سيارت
                      </DwerliButton>
                  </Box>

              </Box>
          </Box>



        </Grid>


        {/* */}
        <Grid item  xs={12} sm={12}  md={6} lg={6} xl={6}>
            <Box component="div" m={1}>
              <Img
                className={classes.heroImage}
                fluid={data.file.childImageSharp.fluid}
                alt="دورلى"
              />
            </Box>
      
        </Grid>

      </Grid>


</Box>
  );
};
