/**
*  Breakpoints 
* - xs, extra-small: 0px
* - sm, small: 600px
* - md, medium: 960px
* - lg, large: 1280px
* - xl, extra-large: 1920px

* @look_at https://material-ui.com/customization/breakpoints/
**/
import React from "react";
//
import { makeStyles } from '@material-ui/core/styles';
//
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
//
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';



/**
 * @custom_style
 */
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '60px 0',
  },
  warrp: {
    margin: '0 auto',
    maxWidth: '91%',
    marginTop: 31,
    marginBottom: '7%'
  },
  title : {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: '30px',
    color: '#201F1F',
    textAlign: 'center',
    marginBottom: '1.35em'
  },
  brand : {
    width: 167,
    height: 41,
    margin: 15,
    padding: 15
  },
}));








/**
 * 
*/
export default (props) => {
  const classes = useStyles();


  const data = useStaticQuery(graphql`
    query IndexImagesQuery {
      one: file(relativePath: { eq: "brands/7.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      two: file(relativePath: { eq: "brands/8.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      three: file(relativePath: { eq: "brands/9.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      four: file(relativePath: { eq: "brands/10.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      five: file(relativePath: { eq: "brands/11.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      six: file(relativePath: { eq: "brands/12.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
 <Box className={classes.root}>

      <Typography variant="h3" gutterBottom className={classes.title}>
        أكثر من 3000 تاجر معتمد على استعداد لشراء سيارتك
      </Typography>


   <Box className={classes.warrp} >

    {/* */}
    <Grid container spacing={3}>


      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.one.childImageSharp.fluid}
          />
        </Box>
      </Grid>

      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.two.childImageSharp.fluid}
          />
        </Box>
      </Grid>


      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.three.childImageSharp.fluid}
          />
        </Box>
      </Grid>

      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.four.childImageSharp.fluid}
          />
        </Box>
      </Grid>

      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.five.childImageSharp.fluid}
          />
        </Box>
      </Grid>


      {/* */}
      <Grid item  sm={6}  md={4} lg={2} xl={2}>
        <Box className={classes.brand}>
          <Img
            fluid={data.six.childImageSharp.fluid}
          />
        </Box>
      </Grid>


      </Grid>

    </Box>
   </Box>
  );
};
