import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { green, pink } from '@material-ui/core/colors';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles((theme) => ({
  badge: {
    '& > .MuiBadge-badge': {
        boxShadow: '8px 8px 14px rgba(0, 0, 0, 0.15)',
        border: '2px solid white',
        borderRadius: 20,
        height: 30,
        width: 30,
        color: 'white',
        backgroundColor: (props) => props.color,
            // responsive
            [theme.breakpoints.up('md')]: {
              top: 52,
              right: 30 
            },
            [theme.breakpoints.down('sm')]: {
              top: 48,
              right: 25
            },

    }
 },  
  round: {
    backgroundColor: (props) => props.color,
    //color: (props) => props.color,
    margin: '23px auto',

    opacity: '25%',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    userSelect: 'none',
    borderRadius: '50%',
    // responsive
    [theme.breakpoints.up('sm')]: {
      width: 59,
      height: 59,
    },
    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50
    },
  }
}));

export default function IconAvatar(props) {
  const classes = useStyles(props);
  
  return (
    <Badge badgeContent={props.number} className={classes.badge} showZero>
      <Box>
        <Box className={classes.round}>

        </Box>
      </Box>
    </Badge>
  );
}